import React, { Component, Suspense } from "react"
import { Switch, Route } from "react-router-dom"
import Loadable from "react-loadable"

import "../../node_modules/font-awesome/scss/font-awesome.scss"

import Loader from "./layout/Loader"
import Aux from "../hoc/_Aux"
import ScrollToTop from "./layout/ScrollToTop"
import UnAuthenticatedRoutes from "../UnAuthenticatedRoute"
import AppApi from "../Api/app_api"
import { Redirect } from "react-router-dom/cjs/react-router-dom"
import "../../node_modules/bootstrap-print-css/css/bootstrap-print.css"
const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader,
})

class App extends Component {
  render() {
    const menu = UnAuthenticatedRoutes.map((route, index) => {
      return route.component ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={(props) => <route.component {...props} />}
        />
      ) : null
    })

    return (
      <Aux>
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Switch>
              {menu}
              {AppApi.isLogin() && <Route path="/" component={AdminLayout} />}
              {!AppApi.isLogin() && <Redirect to="/login" />}
            </Switch>
          </Suspense>
        </ScrollToTop>
      </Aux>
    )
  }
}

export default App
