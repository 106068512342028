import React from "react"
const LoginPage = React.lazy(() => import("./Admin/SignIn"))
const SignUp1 = React.lazy(() => import("./Demo/Authentication/SignUp/SignUp1"))
const Signin1 = React.lazy(() => import("./Demo/Authentication/SignIn/SignIn1"))
const CetakInvoice = React.lazy(() => import("./Admin/Invoices/Cetak"))
const route = [
  { path: "/auth/signup-1", exact: true, name: "Signup 1", component: SignUp1 },
  { path: "/auth/signin-1", exact: true, name: "Signin 1", component: Signin1 },
  {
    path: "/invoices/detail/:uuid",
    exact: true,
    name: "Cetak Invoice",
    component: CetakInvoice,
  },
  {
    path: "/login",
    exact: true,
    name: "Login Page",
    component: LoginPage,
  },
]

export default route
